<template>
  <PCard class="shadow-0 border-0">
    <template #content>
      <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-light text-center" v-if="error">
        <p class="text-danger">Une erreur est survenue lors du chargement de l'offre.</p>
        <p>
          <button @click.prevent.stop="loadData" class="btn btn-primary">
            Réessayer
          </button>
        </p>
      </div>
      <div v-else>
        <div>
          <h4 class="py-0 my-0">{{ offre.intitule }}</h4>
          <div>
            <span>{{ offre.lieuDeDepot }}</span>
          </div>
          <div class="text-muted">
            <span
              >Publié le {{ $dayjs(offre.dateDebutPub).format("MM/DD/YYYY") }} - offre n°
              {{ offre.reference }}</span
            >
          </div>
          <div class="row mt-4">
            <div class="col-md-8">
              <h4>DETAIL DE L'OFFRE</h4>
              <div v-html="offre.descriptionTache"></div>
            </div>
            <div class="col-md-4">
              <div class="col-sm- mb-4">
                <div class="border p-2 h-100">
                  <p class="my-0 fw-bold">Expérience</p>
                  <p class="text-muted my-0">
                    {{ offre.annExp }} {{ offre.uniteExperience }}
                  </p>
                </div>
              </div>
              <div class="col-sm- mb-4">
                <div class="border p-2 h-100">
                  <p class="my-0 fw-bold">Pièces</p>
                  <div v-for="(piece, index) in offre.typePieces" :key="index">
                    <p class="text-muted my-0">{{ piece.libelle }}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm- mb-4">
                <div class="border p-2 h-100">
                  <p class="my-0 fw-bold">Niveau d'étude</p>
                  <div class="d-flex flex-wrap justify-content">
                    <span v-for="(niv, index) in offre.niveaus" :key="index">
                      <span class="text-muted my-0 badge badge-primary" v-if="niv.code">
                        {{ niv.code }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-sm- mb-4">
                <div class="border p-2 h-100">
                  <p class="my-0 fw-bold">Domaine</p>
                  <div class="d-flex flex-wrap justify-content">
                    <div v-for="(domaine, index) in offre.domaines" :key="index">
                      <span class="badge bg-primary text-white rounded- px-4 me-3 mb-2"
                        ><h6 class="m-0">{{ domaine.description }}</h6></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PDivider />
      <h4 class="fw-bold mb-3 my-2">Profil souhaité</h4>
      <div>
        <h6 class="fw-bold">Experience</h6>
        <div>{{ offre.annExp }} {{ offre.uniteExperience }}</div>
      </div>
      <div class="mt-4">
        <h6 class="fw-bold">Langues</h6>
        <PTag
          class="me-2"
          severity="warning"
          v-for="(lang, index) in offre.langues"
          :key="index"
          >{{ lang.nom }}</PTag
        >
      </div>

      <div class="mt-4">
        <h6 class="my-0 fw-bold">Niveau d'étude</h6>
        <span v-for="(niv, index) in offre.niveaus" :key="index">
          <span class="text-muted my-0" v-if="niv.code">
            {{ niv.code }}
          </span>
        </span>
      </div>
    </template>
  </PCard>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    offreId: {
      type: Object,
    },
  },
  data() {
    return {
      offre: {},
      loading: false,
    };
  },
  watch: {
    offreId: {
      immediate: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    ...mapActions({
      getOneOffrePublished: "offre/getOneOffrePublished",
    }),
    loadData() {
      this.loading = true;
      this.error = false;
      this.getOneOffrePublished(this.offreId)
        .then((data) => {
          this.loading = false;
          this.offre = data;
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          console.log(error);
        });
    },
  },
};
</script>
