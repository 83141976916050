/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <PlacementAccepterEditor
      ref="placementAccepterDialog"
      :item="placementValidation"
      :title="'Validation du choix de candidature'"
      @save="savePlacementValidation"
    />
    <CandidatureViewer
      ref="viewer"
      v-if="activeCandidature && activeCandidature.id != null"
      :candidatureId="activeCandidature.id"
      :candidature="activeCandidature"
      @change="queryStatistics"
    />
    <div class="statistic-box" v-if="$can('seeStatistic', 'Candidature')">
      <CandiatureStatistic
        ref="candidatureStatistic"
        :extraQueryArg="extraQueryArg"
        class="mt-3 mb-2"
      />
    </div>
    <div class="container- mt-1">
      <p-progress-bar
        v-if="mxLoading || busy"
        style="height: 0.33em"
        mode="indeterminate"
      />
      <div v-if="$can('seeHeader', 'Candidature')" class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="sexeDe"
                    :options="sexeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Sexe DE'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurVulnerabilite"
                    :options="demandeurVulnerabiliteOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Vulnérabilité'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurType"
                    :options="demandeurTypeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type de DE'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
            <div class="row p-fluid grid">
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="dateDeb">Date de début</label>
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="dateFin">Date de fin</label>
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="statutEvaluation">Statut Evaluation</label>
                <p-dropdown
                  class="flex-grow-1"
                  v-model="statutEvaluation"
                  :options="statutEvaluationOptions"
                  optionLabel="label"
                  optionValue="value"
                  :placeholder="'Statut Evaluation'"
                  display="chip"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="typeCandidature">Type de candidature</label>
                <p-dropdown
                  class="flex-grow-1"
                  v-model="typeCandidature"
                  :options="typeCandidatureOptions"
                  optionLabel="label"
                  optionValue="value"
                  :placeholder="'Type de candidature'"
                  display="chip"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="statutCandidature">Statut de Mise En Relation</label>
                <p-dropdown
                  class="flex-grow-1"
                  v-model="statutCandidature"
                  :options="statutCandidatureOptions"
                  optionLabel="label"
                  optionValue="value"
                  :placeholder="'Statut de Mise En Relation'"
                  display="chip"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                  v-if="option.visible"
                  :key="index"
                >
                  <label :for="option.labelField">{{ option.label }}</label>

                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :emitObject="false"
                    :displayMode="option.displayMode"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
            <div class="d-flex justify-content-end">
              <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
            Matching avec les critères de l'offre
          </PButton> -->
              <PButton
                class="p-button-danger p-button- py-1 p-button-sm ms-1"
                @click.prevent.stop="reinitialiserFiltres"
              >
                Réinitialiser les filtres
              </PButton>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des candidatures</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <DemandeurProfilSelectorDialog
            ref="demandeurSelector"
            :title="'Séléction de profils à recommander'"
            :items="activeRecommandation.demandeurs"
            :offre="currentOffre"
            v-if="currentOffre != null && currentOffre.id != null"
            @save="recommanderProfilSelectionnes"
          />
          <CandidatureTransmissionDialog
            ref="transmissionDialog"
            v-if="currentOffre != null && currentOffre.id != null"
            :title="'Transmission des candidatures'"
            :offre="currentOffre"
            :items="activeTransmission.candidatures"
            @save="transmettrePropositions"
          />
          <AccepationMultipleDialog
            ref="acceptationMultipleDialog"
            :title="'Acceptation multiple'"
            :offre="currentOffre"
            :item="acceptationMultiple"
            @save="saveAcceptationMultiple"
          />
          <a
            @click.prevent="showRecommandationCandidature"
            href=""
            class="style_btn btn btn-warning"
            >Recommander des candidatures</a
          >
          <a
            @click.prevent="showAcceptationMultiple"
            href=""
            class="ms-2 style_btn btn btn-warning"
            >Accepation en masses</a
          >
          <a
            @click.prevent="showTransmissionDialog"
            :disabled="nonEvalueesCountLoading"
            href=""
            class="ms-2 style_btn btn btn-warning"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-if="nonEvalueesCountLoading"
              role="status"
              aria-hidden="true"
            ></span>
            Transmettre la sélection
          </a>
          <button
            v-if="$can('AUTO_EVALUER', 'CANDIDATURE')"
            class="btn btn-lg btn-danger ms-2 style_btn"
            v-tooltip.top="
              'Evaluation automatique donne la même note à tous les candidats'
            "
            @click="autoEvaluer"
          >
            Evaluation automatique
          </button>
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusOffreColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                </template>
                <template #cell(createdDate)="{ item: cand }">
                  {{ $dayjs(cand.createdDate).format("YYYY-MM-DD HH:mm") }}
                </template>
                <template #cell(isEvalue)="{ item: de }">
                  <span class="text-uppercase">
                    <i
                      v-if="de.isEvalue"
                      v-tooltip.left="'Evaluée'"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                      v-badge.success="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                    <i
                      v-else
                      v-tooltip.left="'Non évaluée'"
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                      v-badge.danger="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                  </span>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>
                <template #cell(motivation)="{ item: de }">
                  <div class="ellipsis">
                    {{ de.motivation | truncate() }}
                  </div>
                </template>

                <template #cell(actions)="{ item: cand }">
                  <div
                    v-if="$can('accept', 'Candidature')"
                    class="d-flex justify-content-end"
                  >
                    <!-- <a class="btn btn-success rounded-0 btn-sm me-1" title><i class="bi bi-pencil-square"></i></a> -->
                    <a
                      class="btn btn-info rounded-0 btn-sm me-1"
                      title="Evaluer candidature"
                      @click.stop="showCandidature(cand)"
                      ><i class="bi bi-info-square"></i
                    ></a>
                    <a
                      v-if="cand.status == 'PROPOSE'"
                      class="btn btn-primary rounded-0 btn-sm me-1"
                      title="Accepter ce candidat"
                      @click.stop="accepterCandidature(cand)"
                      >Accepter</a
                    >
                    <a
                      v-else-if="cand.status == 'RECALE'"
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      title="Exiger ce candidat"
                      @click.stop="exigerCandidature(cand)"
                    >
                      Exiger</a
                    >
                    <a
                      v-if="
                        $can('recommend', 'Candidature') &&
                        cand.typeCandidature == 'RECOMMANDE'
                      "
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      v-tooltip.left="'Annuler recommandation'"
                      @click.stop="retirerRecommanationCandidature(cand)"
                    >
                      <i class="bi bi-person-x"></i
                    ></a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }}
                à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <!-- <a @click.prevent="transmettrePropositions()" href="" class="style_btn btn btn-sm btn-outline-primary">Transmettre les candidatures</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../mixins/auth-mixin";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";
import CandiatureStatistic from "../../../components/statistic/candidature/CandiatureStatistic.vue";

import CandidatureViewer from "./candidature/CandidatureViewer.vue";

import PlacementAccepterEditor from "../../../components/espace/common/placement/editor/PlacementAccepterEditor.vue";

import DemandeurProfilSelectorDialog from "../../../components/espace/common/DemandeurProfilSelectorDialog.vue";
import CandidatureTransmissionDialog from "../../../components/espace/entreprise/candidature/CandidatureTransmissionDialog.vue";
import AccepationMultipleDialog from "../../../components/espace/entreprise/candidature/AccepationMultipleDialog.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    offreId: {
      type: Number,
      default: null,
    },
  },
  components: {
    FicheAzoliReport,
    CandidatureViewer,
    PlacementAccepterEditor,
    ReportHeader,
    AsyncMultiSelect,
    AzoliStatusBadge,
    CandiatureStatistic,
    CAutoComplete,
    DemandeurProfilSelectorDialog,
    AccepationMultipleDialog,
    CandidatureTransmissionDialog,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      acceptationMultiple: {
        candidatures: [],
      },
      showAdvancedFilter: false,
      busy: false,
      nonEvalueesCountLoading: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      placementValidation: {},
      currentOffre: {},
      activeTransmission: {},
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      statutEvaluationOptions: [
        { value: null, label: "Tous" },
        { value: "EVALUE", label: "EVALUE" },
        { value: "NON_EVALUE", label: "NON EVALUE" },
      ],
      statutEvaluation: null,
      typeCandidatureOptions: [
        { value: null, label: "Tous" },
        { value: "RECOMMANDE", label: "RECOMMANDE" },
        { value: "SPONTANNEE", label: "SPONTANNEE" },
      ],
      statutCandidatureOptions: [
        { value: null, label: "Tous" },
        { value: "RECOMMANDE", label: "RECOMMANDE" },
        { value: "CANDIDATE", label: "CANDIDATE" },
        { value: "PROPOSE", label: "PROPOSE" },
        { value: "RETENU", label: "RETENU" },
        { value: "EXIGE", label: "EXIGE" },
      ],
      statutCandidature: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "VULNERABLE" },
        { value: false, label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      activeRecommandation: {
        demandeur: [],
      },
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des candidatures",
                subtitle: "Liste des candidatures",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "candidatures/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des candidatures.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des candidatures",
                subtitle: "Liste des candidatures",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "candidatures/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des candidatures.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["candidat.nomPrenom"],
      searchFieldsOptions: [],
      filterOptions: [
        // {
        //   column: "statut",
        //   value: "",
        //   label: "Statut de Mise En Relation",
        //   labelField: "label",
        //   valueKey: "label",
        //   displayMode: "classic",
        //   queryUrl: "",
        //   dropdown: true,
        //   visible: true,
        //   items: [{ value: null }],
        // },
        // {
        //   column: "annExp",
        //   value: "",
        //   label: "Expériences",
        //   labelField: "label",
        //   valueKey: "label",
        //   displayMode: "mega-menu",
        //   queryUrl: "anneeExpriences",
        //   dropdown: true,
        //   visible: true,
        // },
        {
          column: "candidat.langue",
          value: [],
          label: "Langues",
          dropdown: true,
          labelField: "nom",
          displayMode: "mega-menu",
          valueKey: "id",
          queryUrl: "ethnies",
          visible: true,
        },
        // {
        //   column: "candidat.metierAppris",
        //   value: "",
        //   label: "Métier(s) appris",
        //   valueKey: "id",
        //   queryUrl: "romes",
        //   displayMode: "classic",
        //   labelField: "libelle",
        //   dropdown: true,
        //   visible: true,
        // },
        // {
        //   column: "candidat.metierDesires",
        //   value: "",
        //   label: "Métier(s) recherché(s)",
        //   queryUrl: "romes",
        //   valueKey: "id",
        //   labelField: "libelle",
        //   displayMode: "classic",
        //   dropdown: true,
        //   visible: true,
        // },
        // {
        //   column: "candidat.competence",
        //   value: "",
        //   label: "Aptitudes",
        //   getterName: "competences",
        //   valueKey: "id",
        //   displayMode: "mega-menu",
        //   labelField: "intitule",
        //   dropdown: true,
        //   visible: true,
        // },
        {
          column: "candidat.diplome",
          value: "",
          label: "Diplômes",
          valueKey: "id",
          dropdown: true,
          dropdown: true,
          displayMode: "mega-menu",
          labelField: "code",
          queryUrl: "niveaux",
          visible: true,
        },
        {
          column: "candidat.niveauInstruction",
          value: "",
          label: "Niveau instruction",
          dropdown: true,
          valueKey: "id",
          multiple: true,
          labelField: "code",
          displayMode: "mega-menu",
          queryUrl: "instructions",
          visible: true,
        },
        // {
        //   column: "candidat.nature",
        //   value: "",
        //   label: "Nature contrat",
        //   labelField: "code",
        //   displayMode: "mega-menu",
        //   queryUrl: "natures",
        //   valueKey: "id",
        //   dropdown: true,
        //   visible: true,
        // },
        {
          column: "candidat.departement",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "mega-menu",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "candidat.commune",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "candidat.arrondissement",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "candidat.ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeCandidature: {},
    };
  },
  created() {
    this.fetchOffre(this.offreId).then((data) => {
      this.currentOffre = data;
    });
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      pagination: "demandeur/pagination",
    }),
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "candidat.nom", label: "Cand.Nom" },
        { key: "candidat.prenom", label: "Cand.Prénom" },
        { key: "candidat.phone", label: "Cand.Phone" },
        { key: "motivation", label: "Motifivation" },
        { key: "createdDate", label: "Date" },
        { key: "isEvalue", label: "Evaluée" },
        { key: "status", label: "Statut" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      fetchOffre: "offre/getOneOffre",
      accepterPropostionCandidature: "candidature/accepterPropostionCandidature",
      exigerPropostionCandidature: "candidature/exigerPropostionCandidature",
      transmettrePropositionCandidatures:
        "candidature/transmettrePropositionCandidatures",
      accepterCandidaturesEnMasse: "candidature/accepterCandidaturesEnMasse",
      retirerRecommanation: "candidature/retirerRecommanationCandidature",
      autoEvaluerCandidatures: "candidature/autoEvaluerCandidatures",
      fetchCandidatureNonEvaluesCount: "offre/fetchCandidatureNonEvaluesCount",
      recommanderProfils: "offre/recommanderProfils",
    }),
    async autoEvaluer() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment auto-évaluer les candidatures de cette offre?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.busy = true;
          this.autoEvaluerCandidatures({
            offreId: this.offreId,
          })
            .then((data) => {
              this.$toast.success("Auto-Evaluation effectuée avec succès!", {
                position: "top-right",
                duration: 8000,
              });
              this.queryStatistics();
              this.busy = false;
            })
            .catch((err) => {
              this.busy = false;
              this.$toast.error("Une erreure s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 8000,
              });
            });
        },
      });
    },
    reinitialiserFiltres() {
      this.filterOptions.forEach((option) => (option.value = []));
      this.demandeurType = null;
      this.typeCandidature = null;
      this.statutCandidature = null;
      this.statutEvaluation = null;
      this.sexeDe = null;
      this.demandeurVulnerabilite = null;
    },
    showRecommandationCandidature() {
      if (["EN_ATTENTE", "ANNULE"].includes(this.currentOffre.status)) {
        this.$toast.error(
          "Vous ne pouvez pas faire de recommandation sur une offre ayant le statut ${}!",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }

      this.activeRecommandation = {
        offreId: this.offreId,
        demandeurs: [],
      };
      this.$refs.demandeurSelector.show();
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    getStatusOffreColor(status) {
      let color = "";
      if (status == "CANDIDATE") {
        color = "primary";
      } else if (status == "RETENU") {
        color = "success";
      } else if (status == "PRESELECTIONNE") {
        color = "warning";
      } else if (status == "RECALE") {
        color = "danger";
      } else if (status == "PROPOSE") {
        color = "info";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "candidature",
        search: this.search,
        fetcherMethod: "candidature/fetchCandidatures",
        dataGetter: "candidature/candidatures",
        paginationGetter: "candidature/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam(
            "candidat.categorieDemandeur",
            this.demandeurType
          );
          paramPlus = this.mxAddQueryParam(
            "candidat.estVulnerable",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("candidat.sexe", this.sexeDe, paramPlus);
          paramPlus = this.mxAddQueryParam("createdDate", dateDeb, paramPlus);
          paramPlus = this.mxAddQueryParam("createdDate", dateFin, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "isEvalue",
            this.statutEvaluation != null ? this.statutEvaluation == "EVALUE" : null,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "typeCandidature",
            this.typeCandidature,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("status", this.statutCandidature, paramPlus);

          let params = `${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
      if (this.$refs.candidatureStatistic != null)
        this.$refs.candidatureStatistic.loadData();
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },

    showCandidature(candidature) {
      console.log(candidature);
      this.activeCandidature = { ...candidature };
      this.$refs.viewer.show();
    },
    showAcceptationMultiple() {
      this.acceptationMultiple = {
        candidatures: [],
        offreId: this.offreId,
      };
      this.$refs.acceptationMultipleDialog.show();
    },
    saveAcceptationMultiple(acceptation) {
      this.busy = true;
      this.accepterCandidaturesEnMasse(acceptation)
        .then(() => {
          this.$toast.info("Opération réussie!", {
            position: "top-right",
            duration: 8000,
          });
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          this.$toast.error("Une erreure s'est produite lors de l'opération!", {
            position: "top-right",
            duration: 8000,
          });
        });
    },
    savePlacementValidation(placementValidation) {
      if (placementValidation && placementValidation.candidature) {
        console.log(placementValidation);
        if (placementValidation.candidature.status == "RECALE") {
          this.exigerPropostionCandidature({
            candidatureId: placementValidation.candidature.id,
            placementValidation,
          })
            .then((data) => {
              this.queryStatistics();
            })
            .catch((err) => {
              this.busy = false;
              this.$toast.error("Une erreure s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 8000,
              });
            });
        } else if (placementValidation.candidature.status == "PROPOSE") {
          this.accepterPropostionCandidature({
            candidatureId: placementValidation.candidature.id,
            placementValidation,
          })
            .then((data) => {
              this.queryStatistics();
            })
            .catch((err) => {
              this.busy = false;
              this.$toast.error("Une erreure s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 8000,
              });
            });
        }
      } else {
        this.$toast.error(
          "Une erreure s'est produite lors de l'opération! Missing candidature",
          {
            position: "top-right",
            duration: 8000,
          }
        );
      }
    },
    getCurrentOffre() {
      const offre = this.mxFilteredItems[0] != null ? this.mxFilteredItems[0].offre : {};
      return offre;
    },
    accepterCandidature(candidature) {
      this.placementValidation = {
        ...this.emptyPlacementVal,
        candidature,
        // offre: this.getCurrentOffre(),
      };
      this.$refs.placementAccepterDialog.show();
    },
    exigerCandidature(candidature) {
      this.placementValidation = {
        ...this.emptyPlacementVal,
        candidature,
      };
      this.$refs.placementAccepterDialog.show();
    },
    async retirerRecommanationCandidature(candidature) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment retirer cette candidature recommandée de ${candidature.candidat.nomPrenom} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.retirerRecommanation({
            ...candidature,
          })
            .then((data) => {
              this.$toast.success("Recommandation retirée avec succès!", {
                position: "top-right",
                duration: 8000,
              });
              // this.queryStatistics()
            })
            .catch((err) => {
              this.busy = false;
              this.$toast.error("Une erreure s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 8000,
              });
            });
        },
      });
    },
    async recommanderProfilSelectionnes(demandeurs) {
      this.busy = true;

      this.recommanderProfils({
        offreId: this.offreId,
        demandeurs,
      })
        .then((data) => {
          this.$toast.info("Canditatures recommandées avec succès!", {
            position: "top-right",
            duration: 8000,
          });
          this.busy = false;

          this.queryStatistics();
        })
        .catch((err) => {
          this.busy = false;
          this.$toast.error("Une erreure s'est produite lors de l'opération!", {
            position: "top-right",
            duration: 8000,
          });
        });
    },
    async showTransmissionDialog() {
      this.activeTransmission = {
        offreId: this.offreId,
        candidatures: [],
      };
      this.nonEvalueesCountLoading = true;
      let nonEvalueesCount = await this.fetchCandidatureNonEvaluesCount({
        offreId: this.offreId,
      });
      this.nonEvalueesCountLoading = false;

      let offre = this.currentOffre;

      if (offre.niveauService != "SERVICE_NON_DELEGUE" && nonEvalueesCount > 0) {
        let message = `
          Cette offre a un niveau de servce ${offre.niveauService} et comporte ${nonEvalueesCount} candidature(s) non évaluée(s).\n
          Toutes les candidatures doivent être évaluées avant de transmettre les propositions à l'employeur.
        `;
        await this.$confirm.require({
          group: "confirmDialog",
          message,
          header: "Transfert non autorisé",
          icon: "pi pi-exclamation-triangle",
          rejectClass: "d-none",
          accept: () => {},
        });

        // Tant que toutes les candidatures n'ont pas été évaluée. Ne pas continuer.
        return;
      }

      this.$refs.transmissionDialog.show();
    },

    async transmettrePropositions(candidatures) {
      // const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment transmettre les candidatures sélectionnées ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.busy = true;
          this.transmettrePropositionCandidatures({
            offreId: this.offreId,
            candidatures,
          })
            .then((data) => {
              this.busy = false;

              this.queryStatistics();
            })
            .catch((err) => {
              this.busy = false;
              this.$toast.success("Recommandation retirée avec succès!", {
                position: "top-right",
                duration: 8000,
              });
              this.$toast.error("Une erreure s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 8000,
              });
            });
        },
      });
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
